
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import FlashSaleDemo from "@/views/LimitActivities/ActivityDemo/FlashSaleDemo.vue";
@Component({
  components: {
    FlashSaleDemo,
  },
})
export default class ExternalFlashSale extends Mixins(Mixin) {
  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.updateData();
    }
  }

  updateData(): void {
    let ExternalRedEnvelope = this.$refs[
      "exter-flash-sale-demo"
    ] as FlashSaleDemo;
    if (ExternalRedEnvelope) {
      ExternalRedEnvelope.updateData();
    }
  }
}
