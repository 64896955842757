
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { ChannelType } from "@/store/modules/channel";
import { Route } from "vue-router";

@Component({
  components: {},
})
export default class ExternalAccessLayout extends Mixins(Mixin) {
  code = "";
  cityCode = "";
  cityName = "";
  changeView = true;

  @Watch("$route")
  changeRoute(to: Route): void {
    const newCode = String(to.query.code);
    if (this.code !== newCode) {
      this.code = newCode;
      this.init();
    }
    const newCityCode = String(to.query.cityCode);
    const newCityName = String(to.query.cityName);
    if (
      newCityName !== "undefined" ||
      newCityCode !== "undefined" ||
      this.cityCode !== newCityCode ||
      this.cityName !== newCityName
    ) {
      this.cityCode = newCityCode;
      this.cityName = newCityName;
      this.changeCityCode();
    }
    this.changeView = false;
    setTimeout(() => {
      this.changeView = true;
    }, 0);
  }
  mounted(): void {
    /**
     * 所有外部跳转都需要重置 Token、channel、theaterId
     * 只在第一次从其它端跳入 H5 时需要执行
     * 在 mounted 中执行，这样保证子路由组件也已经 mounted 之后再设置这些信息
     * 这样子组件 Watch 到 isExternalAccessReady 的变化时自己一定是 mounted 的，DOM 操作不会有问题
     */
    let query = this.$route.query;
    if (query.code) {
      this.code = String(query.code);
    }
    if (query.theaterId) {
      this.TheaterModule.SET_theaterId_PERSIST(String(query.theaterId));
    }
    this.cityCode = String(query.cityCode) || "";
    this.cityName = String(query.cityName) || "";
    if (this.cityCode !== "undefined" || this.cityName !== "undefined") {
      this.changeCityCode();
    }
    if (
      !this.ExternalAccessModule.code ||
      this.ExternalAccessModule.code !== this.code
    ) {
      this.init();
    }
  }
  init(): void {
    this.UserModule.LOGOUT_PERSIST();
    this.ChannelModule.resetAllData_PERSIST();
    this.TheaterModule.resetAllData_PERSIST();
    this.setExternalAccessInformationByCode();
  }
  changeCityCode(): void {
    if (this.cityName) {
      this.LocationModule.SET_city_PERSIST({
        cityName: this.cityName,
        cityCode: this.cityCode,
      });
    }
  }

  /**
   * 根据 code 参数换取外部跳转相关的信息
   */
  setExternalAccessInformationByCode(): void {
    if (this.code) {
      this.$api.oauth2Api.token.h5Token({ code: this.code }, ({ data }) => {
        // TODO 调用获取用户信息的接口
        let token = data.accessToken || "";
        if (token) {
          this.UserModule.SET_ACCESS_TOKEN_PERSIST(token);
          this.UserModule.SET_IS_LOGIN_PERSIST(true);
        }
        this.ChannelModule.SET_channel_PERSIST(
          (data.channel as ChannelType) || "plat_h5"
        );
        this.ExternalAccessModule.SET_isExternalAccessReady_PERSIST(true);
        this.ExternalAccessModule.SET_CODE(this.code);
      });
    }
  }
}
