
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import LotteryDemo from "@/views/LimitActivities/ActivityDemo/LotteryDemo.vue";
@Component({
  components: {
    LotteryDemo,
  },
})
export default class ExternalLottery extends Mixins(Mixin) {
  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.updateData();
    }
  }

  updateData(): void {
    let ExternalRedEnvelope = this.$refs["exter-lottery-demo"] as LotteryDemo;
    if (ExternalRedEnvelope) {
      ExternalRedEnvelope.updateData();
    }
  }
}
