
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { NavBar, Row, Image, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import HisroryNull from "@/views/LimitActivities/components/Fission/HisroryNull.vue";
import WinLottery from "./WinLottery.vue";
import wx from "weixin-js-sdk";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
    HisroryNull,
    WinLottery,
  },
})
export default class LotteryPrizes extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public allPrize!: Array<cms.ActivityPrizeDto>; //奖品列表

  @Prop({
    type: Number,
    default: 0,
  })
  public integral!: number; //总积分

  @Prop({
    type: Number,
    default: 0,
  })
  public integralConsumption!: number; //抽一次奖消耗积分

  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  public activeId!: string;
  @Prop({
    type: Number,
    default: 0,
  })
  public luckDrawTimes!: number; //抽奖次数

  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  public startTime!: string; //活动开始时间
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  public endTime!: string; //活动结束时间

  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  public ratingPrompt!: string; // 会员等级不足提示

  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  public activityState!: string; //0-待上架  1-已上架 2-已结束',
  @Prop({
    type: Boolean,
    default: true,
  })
  public level!: boolean; //是否满足会员条件

  @Watch("luckDrawTimes", { deep: true, immediate: true })
  getLuckDrawTimes(): void {
    this.numberOfDraws = this.luckDrawTimes;
    // this.numberOfDraws = this.luckDrawTimes > 0 ? this.luckDrawTimes : 0;
  }
  title = "很遗憾，您未中奖哦。";
  showpPrize = false;
  isPrize = false;
  index = 0; // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置
  count = 8; // 总共有多少个位置
  times = 0; // 转动跑格子次数,
  cycle = 60; // 转动基本次数：即至少需要转动多少次再进入抽奖环节
  speed = 110; // 初始转动速度
  // lampShow = false; //开始抽奖，灯光闪烁
  timer = 0; // 转动定时器
  // lamp = 0; // 灯光定时器
  prize = 0; // 中奖位置，接口返回
  numberOfDraws = 0; //限制每天抽奖次数
  prizeData: cms.ActivityPrizeDto = {}; //中奖的奖品
  winPrize: cms.LuckDrawDto = {};
  winningId = ""; //中奖接口返回的中奖id
  isDown = false;
  mounted(): void {
    this.resetData();
  }

  //重置数据
  resetData(): void {
    this.showpPrize = false;
    this.isPrize = false;
    this.index = 0; // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置
    this.count = 8; // 总共有多少个位置
    this.times = 0; // 转动跑格子次数,
    this.cycle = 60; // 转动基本次数：即至少需要转动多少次再进入抽奖环节
    this.speed = 110; // 初始转动速度
    // lampShow = false; //开始抽奖，灯光闪烁
    this.timer = 0; // 转动定时器
    // lamp = 0; // 灯光定时器
    this.prize = 0; // 中奖位置，接口返回
    this.numberOfDraws = 0; //限制每天抽奖次数
    this.prizeData = {}; //中奖的奖品
    this.winPrize = {};
  }

  //关闭
  winClose(): void {
    this.showpPrize = false;
    this.$emit("operate", false);
  }

  //提交
  confirm(): void {
    this.showpPrize = false;
    this.$emit("operate", false);
    if (this.isDown) {
      if (this.ChannelModule.channel === "plat_h5") {
        this.$router.go(-1);
      } else if (
        this.ChannelModule.channel === "plat_ios" ||
        this.ChannelModule.channel === "plat_android"
      ) {
        this.jsBridge("back", "");
      } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
        wx.miniProgram.navigateBack({});
      }
      this.isDown = false;
    }
  }

  /*
   * 去兑换
   */
  goExchange(): void {
    this.$emit("operate", false);
    // 去兑换
    let data = {
      commodityInfoId: this.prizeData.trophyId || "",
      num: 1,
      winningId: this.winningId,
      type: this.winPrize.winningType,
    };
    this.$router.push(`/commodity-exchange?data=${JSON.stringify(data)}`);
    this.showpPrize = false;
  }

  //点击开始抽奖
  move(): void {
    let nowTime = new Date().getTime();
    if (this.ratingPrompt !== "" || this.level == false) {
      this.$toast("会员等级不满足");
      return;
    } else if (nowTime < Number(this.startTime)) {
      this.$toast("活动未开始");
      return;
    } else if (nowTime > Number(this.endTime)) {
      this.title = "活动已结束";
      this.isDown = true;
      this.isPrize = false;
      this.showpPrize = true;

      return;
    } else if (this.activityState === "0") {
      this.$toast("活动未上架");
      return;
    } else if (this.numberOfDraws === 0) {
      this.$toast("你的游戏次数已经用完了，明天再来试试吧");
      return;
    } else if (
      this.integralConsumption > 0 &&
      this.integral < this.integralConsumption
    ) {
      this.$toast("积分不足");
      return;
    }
    if (this.times != 0) {
      this.$toast("正在抽奖中，请勿重复点击");
      this.$emit("operate", true);
      return;
    } else {
      this.$emit("operate", true);
      this.winPrize = { winning: false }; //清空上一次中奖信息
      if (this.numberOfDraws > 0) this.numberOfDraws--; //抽奖开始，次数-1
      this.speed = 110; //每次抽奖速度初始化为200
      this.startRoll(); //执行抽奖
      this.startLottery(); //点击中奖接口
      // this.lamp = setInterval(() => {
      //   //灯光闪烁开启
      //   this.lampShow = !this.lampShow;
      // }, 500);
    }
  }

  //点击抽奖
  startLottery(): void {
    this.$api.cmsApi.lucky.findLuckDrawById(this.activeId, ({ data }) => {
      // this.prizeData = this.allPrize.filter((item) => {
      //   return item.id === this.winPrize.winningId;
      // })[0];
      this.prize = data.index!; //中奖商品下标赋值，
      this.prizeData = this.allPrize[this.prize]; //已经拿到中奖信息，页面展示，等抽奖结束后，将弹窗弹出
      this.winPrize = data;
      this.winningId = data.winningId!;
      (this.$parent as any).getIntegral();
    });
  }

  // 开始转动
  startRoll(): void {
    this.times += 1; // 转动次数
    this.oneRoll(); // 转动过程调用的每一次转动方法，这里是第一次调用初始化
    this.usePrize();
  }

  // 每一次转动
  oneRoll(): void {
    let index = this.index; // 当前转动到哪个位置
    const count = 8; // 总共有多少个位置
    index += 1;
    if (index > count - 1) {
      index = 0;
    }
    this.index = index;
  }

  usePrize(): void {
    // 如果当前转动次数达到要求 && 目前转到的位置是中奖位置
    if (this.times > this.cycle + 10 && this.prize === this.index) {
      clearTimeout(this.timer); // 清除转动定时器
      // clearTimeout(this.lamp); // 清除灯光定时器
      // this.lampShow = false; // 白色灯隐藏
      this.times = 0; // 转动跑格子次数初始化为0，可以开始下次抽奖
      if (this.winPrize.winning === false) {
        //未中奖提示弹出，
        this.title = "很遗憾，您未中奖哦。";
        this.showpPrize = true;
        this.isPrize = false;
      } else {
        //中奖弹出提示
        this.showpPrize = true;
        this.isPrize = true;

        (this.$parent as any).winningRecord(); //调父组件中奖记录方法。
      }
    } else {
      if (this.times < this.cycle - 20) {
        this.speed -= 4; // 加快转动速度
      } else {
        this.speed += 8; // 抽奖即将结束，放慢转动速度
      }
      this.timer = Number(setTimeout(this.startRoll, this.speed)); //开始转动
    }
  }
}
